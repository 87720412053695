import {Dashboard} from '@permworks/eor-dashboard/dist/index.js';
import logoCircleDark from '../../assets/logos/logoCircleDark.png';
import {useNavigate} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../utils/UserContext';
import {ROUTES} from '../../utils/ROUTES';
import {Spin} from 'antd';

export default function EorDashboardPage() {
	const [currentUser] = useContext(UserContext);
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();
	useEffect(() => {
		if (!currentUser?.profile) {
			navigate(ROUTES.AUTHENTICATE);
		} else {
			setIsLoading(false);
		}
	}, [currentUser, navigate]);

	if (isLoading) {
		return <Spin fullscreen />;
	}

	return <Dashboard domain="permworks" logo={logoCircleDark} navigate={() => navigate('/')} />;
}
