import React, {useState} from 'react';
import {Modal, Form, Input, Button, message} from 'antd';
import {setNewPasswordApi} from '../../api/profileApi';
import {permColors} from '../../utils/colors';

export default function NewPasswordPopup({showPopup, closePopup}) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const resetPassword = async (values) => {
		setLoading(true);
		try {
			const res = await setNewPasswordApi(values.oldPassword, values.newPassword);
			if (res === true) {
				message.success('Password changed successfully');
				closePopup();
			} else {
				message.error(res);
			}
		} catch (error) {
			message.error('An error occurred while changing the password');
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal visible={showPopup} onCancel={closePopup} title="Reset Password" footer={null} style={{color: permColors.darkCharcoal}}>
			<Form form={form} onFinish={resetPassword} layout="vertical">
				<Form.Item name="oldPassword" label="Old Password" rules={[{required: true, message: 'Please input your old password!'}]}>
					<Input.Password />
				</Form.Item>
				<Form.Item name="newPassword" label="New Password" rules={[{required: true, message: 'Please input your new password!'}]}>
					<Input.Password />
				</Form.Item>
				<Form.Item
					name="confirmPassword"
					label="Confirm Password"
					dependencies={['newPassword']}
					rules={[
						{required: true, message: 'Please confirm your new password!'},
						({getFieldValue}) => ({
							validator(_, value) {
								if (!value || getFieldValue('newPassword') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('The two passwords do not match!'));
							},
						}),
					]}>
					<Input.Password />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" loading={loading} style={{marginRight: '10px'}}>
						Reset
					</Button>
					<Button onClick={closePopup}>Cancel</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}
