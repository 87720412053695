import React, {useEffect, useState, useContext} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar2 from '../../components/Navbars/Navbar2';
import './JobsHome.scss';
import {getJobs, getLikedJobs, likeJobApi} from '../../api/jobsApi';
import {useNavigate} from 'react-router-dom';
import {CustomPagination} from '../../utils/MaterialUiThemes';
import {UserContext} from '../../utils/UserContext';
import {mainUrl} from '../../api/Urls';
import {AiOutlineHeart, AiFillHeart} from 'react-icons/ai';
import axios from 'axios';
import LoginMessage from '../../components/Popups/LoginMessage';
import {useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {categoryOptionsArray, jobTypeOptionsArray, monthlySalaryOptions} from '../../utils/arrayData/JobsData';
import GoogleModal from '../../components/Popups/GoogleModal';
import {JobModal, SelectedJobCard} from '../LandingPage/LandingPageCards';
import {useMediaQuery} from '@mui/material';
import JobFilters from './JobFilters';
import {Spin} from 'antd';
import {JobCard} from '../LandingPage/JobCard';
import {permColors} from '../../utils/colors';
import {COOKIES} from '../../utils/cookie-names';
import Cookies from 'js-cookie';
import {openNotification} from '../../components/Dashboard/EOR/Team';
import {userExists} from '../../utils/variables/userVariables';

export const jobsPerPage = 20;

export default function JobsHome() {
	const location = useLocation();
	const search = new URLSearchParams(location.search).get('search');

	const navigate = useNavigate();
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [retrigger, setRetrigger] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const engagementOptions = ['Employee', 'Contractor'];
	const [jobs, setJobs] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const [totalJobs, setTotalJobs] = useState(0);
	const [selectedJob, setSelectedJob] = useState({});
	const [googleModal, setGoogleModal] = useState(false);
	const [searchQuery, setSearchQuery] = useState(search || '');
	const [category, setCategory] = useState(undefined);
	const [countryFilter, setCountryFilter] = useState([]);
	const [jobType, setJobType] = useState(undefined);
	const [monthlySalary, setMonthlySalary] = useState(undefined);
	const [engagementType, setEngagementType] = useState(undefined);
	const [datePosted, setDatePosted] = useState(undefined);
	const [likedJobs, setLikedJobs] = useState([]);
	const [localLikedJobs, setLocalLikedJobs] = useState([]);
	const [message, setMessage] = useState('');
	const [popup, setPopup] = useState(false);
	const [jobLoading, setJobLoading] = useState(true);
	const [modalShow1, setModalShow1] = React.useState(false);
	const smallMatches = useMediaQuery('(max-width: 1350px)');

	async function fetchJobs() {
		setJobLoading(true);
		setSelectedJob({});
		const data = await getJobs(currentPage, searchQuery, category, jobType, monthlySalary, engagementType, countryFilter, datePosted);
		console.log(data);
		setJobs(data.results);
		setTotalJobs(data.count);
		if (data.results.length > 0) {
			setSelectedJob(data.results[0]);
			if (Cookies.get(COOKIES.apply_job_redirect)) {
				const jobId = Cookies.get(COOKIES.apply_job_redirect);
				const selectedJob = data.results.find((job) => job.id === jobId);
				setSelectedJob(selectedJob);
				Cookies.remove(COOKIES.apply_job_redirect);
			}
		}
		if (data.count === 0) {
			setSelectedJob({});
		}
		setTotalPages(Math.ceil(data.count / jobsPerPage));
		const searchParams = new URLSearchParams();
		searchParams.set('search', searchQuery);
		navigate(`?${searchParams.toString()}`);
		setJobLoading(false);
		window.scrollTo({top: 0, behavior: 'smooth'});
	}

	useEffect(() => {
		setSelectedJob({});
		fetchJobs();
	}, [currentPage, category, jobType, monthlySalary, engagementType, countryFilter, datePosted]);

	async function fetchLikedJobs() {
		if (!currentUser.id) return;

		const data = await getLikedJobs();
		setLikedJobs(data);
		const newLocalLikedJobs = [];

		data.forEach((job) => {
			job?.likes?.forEach((like) => {
				if (like === currentUser.id) {
					newLocalLikedJobs.push({userId: currentUser.id, jobId: job.id});
				}
			});
		});

		setLocalLikedJobs(newLocalLikedJobs);
	}

	useEffect(() => {
		if (currentUser.id) {
			fetchLikedJobs();
		}
	}, [retrigger, currentUser]);

	useEffect(() => {
		if (jobs?.length > 0) {
			if (!smallMatches) {
				setSelectedJob(jobs[0]);
				setModalShow1(true);
			}
		}
	}, [jobs]);

	const searchFunction = (e) => {
		e.preventDefault();
		fetchJobs();
	};

	function authenticatedButtons(jobSelection) {
		if (currentUser.id === jobSelection.created_by) {
			return;
		} else {
			const isJobLiked = localLikedJobs.find((job) => job.jobId === jobSelection.id && job.userId === currentUser.id);
			return isJobLiked ? (
				<AiFillHeart className="heart fill" onClick={() => heartClicked(jobSelection.id)} />
			) : (
				<AiOutlineHeart className="heart" onClick={() => heartClicked(jobSelection.id)} />
			);
		}
	}

	async function likeJob(id) {
		await likeJobApi(id);
		setRetrigger(!retrigger);
	}

	useEffect(() => {
		if (location && location.search) {
			const params = new URLSearchParams();
			const search = params.get('search');
			if (search) {
				console.log('search:', search);
				setSearchQuery(search);
			}
		}
	}, [location]);

	function heartClicked(id) {
		if (!userExists(currentUser)) {
			setMessage('Please login to like a job');
			setGoogleModal(true);
			return;
		}
		const isJobLiked = localLikedJobs.find((job) => job.jobId === id && job.userId === currentUser.id);
		if (isJobLiked) {
			// Unlike the job
			let filtered = localLikedJobs.filter((job) => job.jobId !== id);
			setLocalLikedJobs(filtered, () => {
				console.log('updated localLikedJobs:', localLikedJobs);
			});
		} else {
			// Like the job
			let newLikedJob = {
				userId: currentUser.id,
				jobId: id,
			};
			setLocalLikedJobs([...localLikedJobs, newLikedJob], () => {
				console.log('updated localLikedJobs:', localLikedJobs);
			});
		}
		likeJob(id);
		console.log('heart clicked', likedJobs);
	}

	function selectJob(job) {
		setSelectedJob(job);
		setModalShow1(true);
	}

	function closePopup() {
		setPopup(false);
	}

	function assignSeachQuery(e) {
		const searchValue = e.target.value;
		setSearchQuery(searchValue);
	}

	return (
		<div className="browse-jobs-container">
			<Helmet>
				<title>Browse Jobs | Permworks</title>
				<meta name="description" content="Explore a wide range of remote job opportunities with companies around the world. Find the job that's right for you." />
			</Helmet>
			<Navbar2 backgroundColor={permColors.backgroundLightCream} boxShadow={null} style={{marginBottom: '0'}} />
			<div className="browseJobs">
				<div className="header pb-[20px] md:pb-[80px]" style={{backgroundColor: permColors.backgroundLightCream}}>
					<div className="inner-header">
						<h1>Browse Jobs</h1>
						<p className="subheader mb-3">Explore a wide range of remote job opportunities with companies around the world. Find the job that's right for you.</p>
						<form>
							<input type="text" placeholder="Search job title" onChange={assignSeachQuery} value={searchQuery} />
							<button className="buttonDarkBlue" onClick={searchFunction} disabled={jobLoading}>
								Find
							</button>
						</form>
						<JobFilters
							category={category}
							setCategory={setCategory}
							jobType={jobType}
							setJobType={setJobType}
							monthlySalary={monthlySalary}
							setMonthlySalary={setMonthlySalary}
							engagementType={engagementType}
							setEngagementType={setEngagementType}
							categoryOptionsArray={categoryOptionsArray}
							jobTypeOptionsArray={jobTypeOptionsArray}
							monthlySalaryOptions={monthlySalaryOptions}
							engagementOptions={engagementOptions}
							countryFilter={countryFilter}
							setCountryFilter={setCountryFilter}
							searchFunction={searchFunction}
							datePosted={datePosted}
							setDatePosted={setDatePosted}
							jobLoading={jobLoading}
						/>
					</div>
				</div>

				<div className="jobsContainer">
					<div className="jobsList">
						<div className="secondContainer">
							<div className="feedContainer">
								{jobLoading && <Spin className="mx-auto my-10" size="large" />}

								{!jobLoading && (
									<div className="allFeeds">
										{jobs.map((job) => (
											<JobCard key={job.id} job={job} isSelected={job.id === selectedJob.id} onJobSelect={selectJob} authenticatedButtons={authenticatedButtons} />
										))}
									</div>
								)}

								{selectedJob?.title && !smallMatches && <SelectedJobCard job={selectedJob} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />}

								{selectedJob?.title && smallMatches && (
									<JobModal job={selectedJob} show={modalShow1} onHide={() => setModalShow1(false)} onNavigate={navigate} authenticatedButtons={authenticatedButtons} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{totalJobs > 20 && (
				<div className="pages mx-auto my-3">
					<CustomPagination disabled={jobLoading} count={totalPages} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
				</div>
			)}
			<LoginMessage message={message} showPopup={popup} closePopup={closePopup} />
			<GoogleModal
				openGoogle={googleModal}
				dialogTitle="Login to Save Job"
				dialogContent="You need to login to save this job"
				button2="Login"
				closeGoogle={() => setGoogleModal(false)}
				button2function={() => navigate('/authenticate')}
			/>
			<Footer />
		</div>
	);
}
