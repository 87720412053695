import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, message} from 'antd';
import Navbar from '../../components/Navbars/Navbar2';
import {Helmet} from 'react-helmet';
import {ROUTES} from '../../utils/ROUTES';

const EorVerification = () => {
	const [isSuccess, setIsSuccess] = useState(null);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const success = params.get('success');
		const token = params.get('token');

		if (success === 'yes' && token) {
			setIsSuccess(true);
			message.success('Verification successful!');
		} else if (success === 'no') {
			setIsSuccess(false);
			message.error('Verification failed. Please try again.');
		} else {
			// Invalid parameters, redirect to home
			navigate(ROUTES.HOME);
		}
	}, [location, navigate]);

	return (
		<div>
			<Helmet>
				<title>EOR Verification | Permworks</title>
				<meta name="description" content="Verify your EOR account with Permworks" />
			</Helmet>
			<Navbar />
			<div className="flex flex-col items-center  min-h-screen bg-gray-100">
				<div className="max-w-md w-full bg-white mt-10 shadow-md rounded-lg p-8 text-center">
					{isSuccess === true && (
						<>
							<h1 className="text-2xl font-bold text-green-600 mb-4">Congratulations!</h1>
							<p className="text-gray-700 mb-4">Your account has been successfully verified.</p>
							<Button type="default" onClick={() => navigate(ROUTES.EOR_DASHBOARD)}>
								Go to EOR Dashboard
							</Button>
						</>
					)}
					{isSuccess === false && (
						<>
							<h1 className="text-2xl font-bold text-red-600 mb-4">Verification Failed</h1>
							<p className="text-gray-700 mb-4">There was an error verifying your account. Please try again or contact support.</p>
							<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => navigate(ROUTES.HOME)}>
								Return to Home
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default EorVerification;
