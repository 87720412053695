import React, {useState} from 'react';
import {Typography, Form, Input, Radio, Select, Upload, Button, message} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import {createProfileApi} from '../../api/profileApi';
import {Helmet} from 'react-helmet';
import {countries} from '../../utils/arrayData/CountryArray';
import Loading from '../../components/Loaders/Loading';
import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import {COOKIES} from '../../utils/cookie-names';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';
import {permColors} from '../../utils/colors';

const {Title, Text} = Typography;

export default function CreateProfile() {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [imageUrl, setImageUrl] = useState(null);

	const onFinish = async (values) => {
		try {
			setLoading(true);
			const result = await createProfileApi(values.firstName, values.lastName, values.userType, values.country, values.profileImage?.fileList[0]?.originFileObj);

			if (!result.success) {
				message.error(result.error);
				return;
			}

			if (Cookies.get(COOKIES.apply_job_redirect)) {
				navigate(Cookies.get(COOKIES.current_location));
			}

			window.location.reload();
		} catch (error) {
			console.error(error);
			message.error(error.toString());
		} finally {
			setLoading(false);
		}
	};

	const handleImageChange = (info) => {
		if (info.file.status === 'uploading') {
			return;
		}
		if (info.file.status === 'done') {
			// Get the preview URL of the uploaded image
			const reader = new FileReader();
			reader.onload = (e) => {
				setImageUrl(e.target.result);
			};
			reader.readAsDataURL(info.file.originFileObj);
		} else if (info.file.status === 'error') {
			message.error('Image upload failed');
		}
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<div className="min-h-screen" style={{backgroundColor: permColors.backgroundGrey}}>
			<Navbar />
			<Helmet>
				<title>Create Profile | Permworks</title>
				<meta name="description" content="Create your Permworks profile. Showcase your skills and experience to potential employers." />
			</Helmet>

			<div className="max-w-2xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
				<div className="bg-white rounded-lg shadow-sm p-8">
					<div className="text-center mb-8">
						<Title level={2}>Create your profile</Title>
						<Text className="text-gray-600 text-lg">Let's get started</Text>
						<Text className="block mt-2 font-semibold text-gray-800">Create Your Profile, Open Doors to New Opportunities.</Text>
					</div>

					<Form
						form={form}
						layout="vertical"
						onFinish={onFinish}
						initialValues={{
							userType: 'Job-Seeker',
						}}>
						<Form.Item label="I am a" name="userType" rules={[{required: true, message: 'Please select your user type'}]}>
							<Radio.Group className="w-full">
								<div className="w-full flex">
									<Radio.Button value="Job-Seeker" className="text-center h-12 leading-[44px] flex-1">
										Job Seeker
									</Radio.Button>
									<Radio.Button value="Employer" className="text-center h-12 leading-[44px] flex-1">
										Employer
									</Radio.Button>
								</div>
							</Radio.Group>
						</Form.Item>

						<div className="grid grid-cols-2 gap-4">
							<Form.Item label="First Name" name="firstName" rules={[{required: true, message: 'Please enter your first name'}]}>
								<Input size="large" placeholder="Enter your first name" />
							</Form.Item>

							<Form.Item label="Last Name" name="lastName" rules={[{required: true, message: 'Please enter your last name'}]}>
								<Input size="large" placeholder="Enter your last name" />
							</Form.Item>
						</div>

						<Form.Item label="Profile Image" name="profileImage">
							<Upload
								maxCount={1}
								accept="image/png,image/jpeg,image/gif,image/heic,image/heif"
								listType="picture-circle"
								showUploadList={false}
								onChange={handleImageChange}
								customRequest={({file, onSuccess}) => {
									setTimeout(() => {
										onSuccess('ok');
									}, 0);
								}}>
								{imageUrl ? (
									<div className="w-24 h-24 rounded-full overflow-hidden">
										<img src={imageUrl} alt="Profile" className="w-full h-full object-cover" />
									</div>
								) : (
									<div className="flex flex-col items-center justify-center w-24 h-24 rounded-full border-2 border-dashed border-gray-300 hover:border-blue-600 cursor-pointer">
										<PlusCircleOutlined style={{fontSize: '24px', color: '#666'}} />
										<div className="mt-2 text-xs text-gray-500">Upload</div>
									</div>
								)}
							</Upload>
						</Form.Item>

						<Form.Item label="Country" name="country" rules={[{required: true, message: 'Please select your country'}]}>
							<Select
								size="large"
								placeholder="Select your country"
								options={countries.map((country) => ({
									label: country,
									value: country,
								}))}
							/>
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" size="large" className="w-full bg-blue-600">
								Create Profile
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
			<Footer />
		</div>
	);
}
