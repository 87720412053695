import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './ConfirmEmail.scss';
import Navbar from '../../components/Navbars/Navbar2';
import {useLocation} from 'react-router-dom';
import {mainUrl} from '../../api/Urls';
import {Helmet} from 'react-helmet';
import {Input, Spin} from 'antd';

export default function ConfirmEmail() {
	const [isTimedOut, setIsTimedOut] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();
	const [email, setEmail] = useState(location?.state?.email || '');
	const [emailInput, setEmailInput] = useState('');
	const [emailError, setEmailError] = useState('');
	const [confirmationSent, setConfirmationSent] = useState(false);

	useEffect(() => {
		if (isTimedOut) {
			const timeoutId = setTimeout(() => {
				setIsTimedOut(false);
			}, 120000);
			return () => clearTimeout(timeoutId);
		}
	}, [isTimedOut]);

	function requestConfirmation() {
		setIsLoading(true);
		axios
			.post(mainUrl + '/auth/resend-email-verify/', {
				email: email,
			})
			.then(({data}) => {
				console.log(data);
				setIsTimedOut(true);
				setConfirmationSent(true);
			})
			.catch((error) => {
				console.error(error.response.data);
				setEmailError(error.response.data.error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	function customerConfirm() {
		setIsLoading(true);
		axios
			.post(mainUrl + '/auth/resend-email-verify/', {
				email: emailInput,
			})
			.then(({data}) => {
				console.log(data);
				setIsTimedOut(true);
				setConfirmationSent(true);
				setEmail(emailInput);
			})
			.catch((error) => {
				console.error(error.response.data);
				setEmailError(error.response.data.error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	return (
		<div>
			<Helmet>
				<title>Confirm Email | Permworks</title>
				<meta name="description" content="Confirm your email address to complete your registration. Please check your inbox for the confirmation link." />
			</Helmet>
			<Navbar />
			<div className="confirmEmail">
				<div className="confirmCard">
					<h1>Confirm Email</h1>

					{email ? (
						<>
							<h2>Thank you for signing up!</h2>
							<p>Please confirm your email address to complete your account registration.</p>
							<div className="cardText">
								<p>Once you confirm your email, you will be able to access all the features of our site.</p>{' '}
								<span className="email">
									A confirmation email has been sent to <strong>{email}</strong>
								</span>
								<button className="buttonNavy" onClick={requestConfirmation} disabled={isTimedOut || isLoading}>
									{isLoading ? <Spin /> : 'Resend Confirmation'}
								</button>
							</div>
						</>
					) : (
						<>
							<p>Please enter your email address to receive a confirmation email.</p>
							<div className="cardText">
								<Input type="email" placeholder="Email Address" required onChange={(e) => setEmailInput(e.target.value)} disabled={isLoading} />
								<button className="buttonRed" onClick={customerConfirm} disabled={isTimedOut || isLoading || !emailInput}>
									{isLoading ? <Spin /> : 'Send Email'}
								</button>
							</div>
						</>
					)}

					{emailError && <p className="error">{emailError}</p>}
					{confirmationSent && <p className="timeout">A new confirmation email has been sent</p>}
					{confirmationSent && <p className="timeout">Please check your spam folder if you don't see it in your inbox</p>}
					{isTimedOut && <p className="timeout">Please wait 2 minutes before requesting another confirmation email</p>}
				</div>
			</div>
		</div>
	);
}
