import React, {useEffect, useState} from 'react';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbars/Navbar2';
import {countries} from '../../utils/arrayData/CountryArray';
import {categoryOptions, serviceType} from '../../utils/arrayData/PortfolioArrays';
import './EditJobForm.scss';
import {FiX} from 'react-icons/fi';
import {editJobApi, getJobById, postJobApi} from '../../api/jobsApi';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Alert, CircularProgress} from '@mui/material';
import {useParams} from 'react-router-dom';
import Loading from '../../components/Loaders/Loading';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import {Helmet} from 'react-helmet';
import {useNavigate} from 'react-router-dom';
import {permColors} from '../../utils/colors';
import {textFormatter} from '../../utils/TextAlterations';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {Radio} from 'antd';

export default function EditJobForm() {
	const navigate = useNavigate();
	const {jobId} = useParams('jobId');
	const [fetchedJob, setFetchedJob] = useState(null);
	const [loading, setLoading] = useState(true);

	const [jobTitle, setJobTitle] = useState(fetchedJob?.title);
	const [description, setDescription] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [country, setCountry] = useState('');
	const [jobType, setJobType] = useState('');
	const [category, setCategory] = useState('');
	const [engagementType, setEngagementType] = useState('');
	const [monthlySalary, setMonthlySalary] = useState('');
	const [maxMonthlySalary, setMaxMonthlySalary] = useState('');
	const [hourlyRate, setHourlyRate] = useState('');
	const [maxHourlyRate, setMaxHourlyRate] = useState('');
	const [questions, setQuestions] = useState([]);
	const [coverLetterRequired, setCoverLetterRequired] = useState(false);

	const categories = categoryOptions;
	const countriesList = countries;
	const [currentQuestion, setCurrentQuestion] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [isApplying, setIsApplying] = useState(false);

	const [activeStep, setActiveStep] = React.useState(0);

	const [open, setOpen] = React.useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const fetchJob = async () => {
		const res = await getJobById(jobId);
		setFetchedJob(res);
		setJobTitle(res.title);
		setDescription(res.description);
		setCompanyName(res.company_name);
		setCountry(res.country);
		setJobType(res.job_type);
		setCategory(res.category);
		setEngagementType(res.engagement_type);
		setMonthlySalary(res.monthly_salary);
		setMaxMonthlySalary(res.max_monthly_salary);
		setHourlyRate(res.hourly_rate);
		setMaxHourlyRate(res.max_hourly_rate);
		setQuestions(res.questions);
		setCoverLetterRequired(res.provide_cover_letter);
		setLoading(false);
	};

	useEffect(() => {
		fetchJob();
	}, []);

	const customFont = {
		fontFamily: 'Plus Jakarta Sans, sans-serif',
	};

	const handleNextStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBackStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleJobTypeChange = (e) => {
		setJobType(e.target.value);
	};

	const handleEngagementTypeChange = (e) => {
		setEngagementType(e.target.value);
	};

	const addQuestion = () => {
		if (currentQuestion.trim()) {
			setQuestions([...questions, {question: currentQuestion}]);
			setCurrentQuestion('');
		}
	};

	const validateForm = () => {
		let missingFields = [];

		if (!jobTitle) missingFields.push(' job title');
		if (!description) missingFields.push(' description');
		if (!companyName) missingFields.push(' company name');
		if (!country) missingFields.push(' country');
		if (!jobType) missingFields.push(' job type');
		if (!category) missingFields.push(' category');
		if (!engagementType) missingFields.push(' engagement type');

		if (missingFields.length > 0) {
			setErrorMsg(`Please fill in the following required fields:${missingFields.join(', ')}.`);
			return false;
		} else {
			setErrorMsg('');
			return true;
		}
	};

	const removeQuestion = (index) => {
		const newQuestions = [...questions];
		newQuestions.splice(index, 1);
		setQuestions(newQuestions);
	};

	const handlePublish = async () => {
		if (validateForm()) {
			setIsApplying(true);
			const res = await editJobApi(
				jobId,
				jobTitle,
				description,
				companyName,
				country,
				jobType,
				category,
				engagementType,
				monthlySalary,
				maxMonthlySalary,
				hourlyRate,
				maxHourlyRate,
				questions,
				coverLetterRequired
			);
			setIsApplying(false);
			if (res) {
				// handleClick();
				navigate(`/jobpage/${res.id}`);
			}
		}
	};

	const steps = [
		{
			label: 'Job Details',
			description: (
				<>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Title</Form.Label>
						<Form.Control defaultValue={fetchedJob?.title} type="text" placeholder="Virtual assistant" onChange={(e) => setJobTitle(e.target.value)} />
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Company name</Form.Label>
						<Form.Control defaultValue={fetchedJob?.company_name} type="email" placeholder="Permworks" onChange={(e) => setCompanyName(e.target.value)} />
					</Form.Group>

					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Categories</Form.Label>
						<Form.Select defaultValue={fetchedJob?.category} as="select" onChange={(e) => setCategory(e.target.value)}>
							<option value="">Select Category</option>
							{categories.map((category, index) => (
								<option key={index} value={category}>
									{category}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Country</Form.Label>
						<Form.Select aria-label="Default select example" defaultValue={fetchedJob?.country} onChange={(e) => setCountry(e.target.value)}>
							<option value="">Select Country</option>
							{countriesList.map((country, index) => (
								<option key={index} value={country}>
									{country}
								</option>
							))}
						</Form.Select>{' '}
					</Form.Group>
				</>
			),
		},
		{
			label: 'Job Description',
			description: (
				<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
					<Form.Label>Description</Form.Label>
					{/* <Form.Control defaultValue={fetchedJob?.description} onChange={(e) => setDescription(e.target.value)} as="textarea" rows={3} /> */}
					<CKEditor
						editor={ClassicEditor}
						config={{
							toolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList', 'blockQuote'],
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							setDescription(data);
						}}
						data={fetchedJob?.description}
					/>
				</Form.Group>
			),
		},
		{
			label: 'Hiring Details',
			description: (
				<>
					<p>Are you hiring for a full-time, part-time or a one-off team member?</p>
					<Radio.Group onChange={handleJobTypeChange} value={jobType} optionType="button" buttonStyle="solid">
						<Radio value="Full-Time">Full-Time</Radio>
						<Radio value="Part-Time">Part-Time</Radio>
						<Radio value="One-time project">One-time project</Radio>
					</Radio.Group>
					<br />
					<br />
					<p>Will the successful candidate be engaged as a contractor or employee?</p>
					<Radio.Group onChange={handleEngagementTypeChange} value={engagementType} optionType="button" buttonStyle="solid" className="mb-3">
						<Radio value="Employee">Employee</Radio>
						<Radio value="Contractor">Contractor</Radio>
					</Radio.Group>
				</>
			),
		},
		{
			label: 'Salary Offer (USD)',
			description: (
				<div>
					<Form.Select aria-label="Default select example" className="mb-3 customSelect" onChange={(e) => setPaymentMethod(e.target.value)}>
						<option value="">Select Payment Type</option>
						<option value="fixed">Pay a Fixed Price</option>
						<option value="hourly">Pay by the Hour</option>
					</Form.Select>
					{paymentMethod === 'fixed' && (
						<>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon3">Fixed price (USD)</InputGroup.Text>
								<Form.Control
									defaultValue={fetchedJob?.monthly_salary}
									type="number"
									id="basic-url"
									aria-describedby="basic-addon3"
									onChange={(e) => setMonthlySalary(e.target.value)}
								/>
							</InputGroup>
							{/* <InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon3">Fixed price (USD)</InputGroup.Text>
								<Form.Control
									defaultValue={fetchedJob?.max_monthly_salary}
									type="number"
									id="basic-url"
									aria-describedby="basic-addon3"
									onChange={(e) => setMaxMonthlySalary(e.target.value)}
								/>
							</InputGroup> */}
						</>
					)}
					{paymentMethod === 'hourly' && (
						<>
							<InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon3">Hourly rate (USD)</InputGroup.Text>
								<Form.Control defaultValue={fetchedJob?.hourly_rate} type="number" id="basic-url" aria-describedby="basic-addon3" onChange={(e) => setHourlyRate(e.target.value)} />
							</InputGroup>
							{/* <InputGroup className="mb-3">
								<InputGroup.Text id="basic-addon3">Max hourly rate (USD)</InputGroup.Text>
								<Form.Control
									defaultValue={fetchedJob?.max_hourly_rate}
									type="number"
									id="basic-url"
									aria-describedby="basic-addon3"
									nChange={(e) => setMaxHourlyRate(e.target.value)}
								/>
							</InputGroup> */}
						</>
					)}
				</div>
			),
		},

		{
			label: 'Questions',
			description: (
				<>
					<div className="questionsTab">
						<p>What questions would you like candidates to be aware of?</p>

						<InputGroup className="mb-3">
							<Form.Control
								value={currentQuestion}
								onChange={(e) => setCurrentQuestion(e.target.value)}
								placeholder="Question"
								aria-label="Recipient's username"
								aria-describedby="basic-addon2"
							/>
							<Button onClick={addQuestion} variant="outline-secondary" id="button-addon2">
								Add
							</Button>
						</InputGroup>
						<div className="questionsList">
							<ListGroup className="mb-3">
								{questions.map((questionObj, idx) => (
									<ListGroup.Item key={idx} className="d-flex justify-content-between align-items-start">
										<div style={{width: '80%'}}>{questionObj.question}</div>
										<FiX className="icon" onClick={() => removeQuestion(idx)} />
									</ListGroup.Item>
								))}
							</ListGroup>
						</div>
					</div>

					{/* create a checkbox for coverletter required? */}

					<div className="mb-2 customCheckbox">
						<Form.Check // prettier-ignore
							id={`default`}
							label={`Cover letter required?`}
							onChange={(e) => setCoverLetterRequired(e.target.checked)}
						/>
					</div>
				</>
			),
		},
		{
			label: 'Review',
			description: (
				<>
					<p>Review your information here:</p>
					<ListGroup as="ul">
						<p>Job Details:</p>
						<ListGroup.Item as="li">
							<div className="fw-bold">Title</div>
							{jobTitle}
						</ListGroup.Item>
						<ListGroup.Item as="li">
							<div className="fw-bold">Description</div>
							<div dangerouslySetInnerHTML={{__html: textFormatter(description)}} />
						</ListGroup.Item>
						<ListGroup.Item as="li">
							<div className="fw-bold">Company Name</div>
							{companyName}
						</ListGroup.Item>
						<ListGroup.Item as="li">
							<div className="fw-bold">Country</div>
							{country}
						</ListGroup.Item>
						<ListGroup.Item as="li">
							<div className="fw-bold">Category</div>
							{category}
						</ListGroup.Item>
						<ListGroup.Item as="li">
							<div className="fw-bold">Job Type</div>
							{jobType}
						</ListGroup.Item>
						<ListGroup.Item as="li">
							<div className="fw-bold">Engagement Type</div>
							{engagementType}
						</ListGroup.Item>
						<ListGroup.Item as="li">
							<div className="fw-bold">Payment Method</div>
							{paymentMethod === 'fixed' ? 'Fixed Price' : 'Hourly'}
						</ListGroup.Item>
						<ListGroup.Item as="li">
							<div className="fw-bold">{paymentMethod === 'hourly' ? 'Hourly Rate' : 'Monthly Salary'}</div>
							{paymentMethod === 'hourly' ? `${hourlyRate} - ${maxHourlyRate}` : `${monthlySalary} - ${maxMonthlySalary}`}
						</ListGroup.Item>
						{questions.length > 0 && <p className="mt-2 mb-0 font-semibold">Questions</p>}
						{questions.map((questionObj, idx) => (
							<ListGroup.Item as="li" key={idx}>
								<div className="fw-bold">Question {idx + 1}</div>
								{questionObj.question}
							</ListGroup.Item>
						))}
						<ListGroup.Item as="li" className="mb-3">
							<div className="fw-bold">Cover Letter Required?</div>
							{coverLetterRequired ? 'Yes' : 'No'}
						</ListGroup.Item>
					</ListGroup>
				</>
			),
		},
		{
			label: 'Publish',
			description: (
				<>
					<p>Ready to publish your job</p>{' '}
					<button className="buttonLinkBlue" onClick={handlePublish} disabled={isApplying}>
						{isApplying ? <CircularProgress size={24} sx={{color: permColors.white}} /> : 'Publish'}
					</button>
				</>
			),
		},
	];

	if (loading) {
		return <Loading />;
	}

	return (
		<div style={{backgroundColor: permColors.backgroundGrey}}>
			<Helmet>
				<title>Edit Job | Permworks</title>
				<meta name="description" content="Update your job listing details. Ensure your job listing attracts the best candidates by providing clear and accurate information." />
			</Helmet>
			<Navbar />
			<div className="editJobForm">
				<h1>Edit Job</h1>
			</div>
			<div className="materialJob shadow-none flex items-center" style={{backgroundColor: permColors.white}}>
				<Box style={{maxWidth: '100%'}}>
					<Stepper activeStep={activeStep} orientation="vertical">
						{steps.map((step, index) => (
							<Step key={step.label} style={{width: '100%'}}>
								<StepLabel
									onClick={() => setActiveStep(index)}
									style={{cursor: 'pointer', fontFamily: 'Plus Jakarta Sans, sans-serif'}}
									optional={index === 5 ? <Typography variant="caption">Last step</Typography> : null}
									sx={customFont}>
									<p style={{margin: '0'}}>{step.label}</p>
								</StepLabel>
								<StepContent style={{width: '100%'}}>
									<Typography style={{width: '500px', maxWidth: '100%'}} sx={customFont}>
										{step.description}
									</Typography>
									<Box sx={{mb: 2}}>
										<div>
											{index !== steps.length - 1 && (
												<Button variant="contained" onClick={handleNextStep} sx={{mt: 1, mr: 1}}>
													Continue
												</Button>
											)}
											<Button disabled={index === 0} onClick={handleBackStep} sx={{mt: 1, mr: 1}}>
												Back
											</Button>
											{errorMsg && (
												<Alert severity="error" className="mt-2">
													{errorMsg}
												</Alert>
											)}
										</div>
									</Box>
								</StepContent>
							</Step>
						))}
					</Stepper>
				</Box>
				<Stack spacing={2} sx={{width: '100%'}}>
					<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
						<Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
							This job has been successfully updated!
						</Alert>
					</Snackbar>
				</Stack>
			</div>
			<Footer />
		</div>
	);
}
