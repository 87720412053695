import React, {useEffect, useState, useContext} from 'react';
import {useParams} from 'react-router-dom';
import {Card, Typography, Divider, Tag, Space, Collapse, Alert} from 'antd';
import {Helmet} from 'react-helmet';
import {capitalize} from '@material-ui/core';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

import Navbar from '../../components/Navbars/Navbar2';
import Footer from '../../components/Footer/Footer';
import {UserContext} from '../../utils/UserContext';
import './JobPage.scss';
import Loading from '../../components/Loaders/Loading';
import {getJobById} from '../../api/jobsApi';
import {textFormatter} from '../../utils/TextAlterations';
import {formatDate2} from '../../utils/DateFunctions';
import ApplyJobButton from '../../components/ApplyJob/ApplyJobButton';
import {permColors, tailwindPermColors} from '../../utils/colors';

const {Title, Text, Paragraph} = Typography;
const {Panel} = Collapse;

export default function JobPage() {
	const [job, setJob] = useState({});
	const {jobId} = useParams();
	const [currentUser, setCurrentUser] = useContext(UserContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchJob() {
			const jobData = await getJobById(jobId);
			setJob(jobData);
			setLoading(false);
		}
		fetchJob();
	}, [currentUser]);

	if (loading) {
		return <Loading />;
	}

	return (
		<div className={tailwindPermColors.backgroundLightCream}>
			<Helmet>
				<title>{`${job?.title ? job?.title : 'Job'} | Permworks`}</title>
				<meta name="description" content={`Apply for ${job?.title ? job?.title : 'Job'} at Permworks. Join our global talent network.`} />
			</Helmet>
			<Navbar />
			<div className="py-8 px-4 sm:px-6 lg:px-8">
				<Card className="max-w-4xl mx-auto">
					<div className="flex flex-wrap items-center justify-between mb-4">
						<div className="flex items-center gap-2">
							{!job?.created_by_image ? (
								<WorkOutlineOutlinedIcon className="text-4xl" />
							) : (
								<img src={job?.created_by_image} alt="profile" className="w-12 h-12 rounded-full bg-grey-200" />
							)}
							<Title level={2} className={tailwindPermColors.darkCharcoal} className="p-0 m-0">
								{job?.title && capitalize(job.title)}
							</Title>
						</div>
						<Space>
							{job?.job_type && <Tag color="red">{capitalize(job.job_type)}</Tag>}
							<ApplyJobButton job={job} />
						</Space>
					</div>

					<Divider />

					{job?.description && (
						<Paragraph className={tailwindPermColors.charcoal}>
							<div dangerouslySetInnerHTML={{__html: textFormatter(job.description)}} />
						</Paragraph>
					)}

					<Divider />

					<Space direction="vertical" size="small" className={tailwindPermColors.charcoal}>
						{job?.company_name && (
							<Text>
								Company name: <Text strong>{capitalize(job.company_name)}</Text>
							</Text>
						)}
						{job?.engagement_type && (
							<Text>
								Engagement type: <Text strong>{capitalize(job.engagement_type)}</Text>
							</Text>
						)}
						{job?.created_at && (
							<Text>
								Posted on the <Text strong>{formatDate2(job?.created_at)}</Text>
							</Text>
						)}
						{job?.category && (
							<Text>
								Category: <Tag color="blue">{capitalize(job.category)}</Tag>
							</Text>
						)}
						{job?.country && (
							<Space>
								<Text strong>{capitalize(job.country)}</Text>
								<LocationOnRoundedIcon />
							</Space>
						)}
					</Space>

					<Divider />

					{job?.questions?.length > 0 ? (
						<Collapse>
							<Panel header={job?.questions.length > 1 ? 'Application Questions' : 'Application question'} key="1">
								<Space direction="vertical" size="small">
									{job.questions?.map((q, index) => (
										<Text key={index}>
											{index + 1}. {capitalize(q?.question)}
										</Text>
									))}
								</Space>
							</Panel>
						</Collapse>
					) : (
						<Alert message="No required questions to answer for this job" type="info" showIcon />
					)}
				</Card>
			</div>
			<Footer />
		</div>
	);
}
